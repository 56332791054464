











import Vue from 'vue'

import InterestExploreNavigation from '../components/interests/InterestExploreNavigation.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default Vue.extend({
  name: 'InterestsExplore',
  components: { InterestExploreNavigation },
  computed: {
    ...mapGetters(['get_width'])
  },
  methods: {
    ...mapActions('interests', ['getCollectionData']),
    ...mapActions('adAccounts', ['loadAccounts']),
    ...mapMutations('interests', ['resetSearchResults']),
    createModal () {
      this.$modal.show('interest-creating-project')
    }
  },
  mounted () {
    this.getCollectionData({ id: this.$route.params.id }).catch(() => this.$router.push({ name: '404' }))
    this.resetSearchResults()
  }
})
