























import Vue from 'vue'

import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'InterestExploreNavigation',
  props: {
    selecting: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      routes: [
        {
          name: 'Search interests',
          path: '/interests/' + this.$route.params.id + '/explore/search'
        },
        {
          name: 'Selected',
          path: '/interests/' + this.$route.params.id + '/explore/selected/manage'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['get_width'])
  },
  components: {},
  methods: {}
})
